:root {
  --sizecube: 40em;
  --sizecube_cateto: 30em;
  --sizecube_2: 20em;
  --sizecube_4: 5em;
  --perspective: 500em;
  --perspective_origin_x: 40%;
  --perspective_origin_y: 0%;
  --rot_x: 90deg;
  --rot_y: 30deg;
  
}

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }



a { color:white; text-decoration:none; }

body {
  overscroll-behavior-y: contain;
  background-image: url("./assets/images/inicio_background_nube.gif");
  
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 1080px rgba(15, 15, 15, 0.2);

  height: 100vh;
  width: 100vw;
  padding:0;
  margin:0;
}

.viewport {
 
position:fixed;
  top: 37.5%;
  left: 37.5%;
  width: var(--sizecube);
  height: var(--sizecube_cateto);

  
  -webkit-perspective: var(--perspective);
  -moz-perspective: var(--perspective);
  -ms-perspective: var(--perspective);
  -o-perspective: var(--perspective);
  perspective: var(--perspective);

  -webkit-perspective-origin: var(--perspective_origin_x) var(--perspective_origin_y);
  -moz-perspective-origin: var(--perspective_origin_x) var(--perspective_origin_y);
  -ms-perspective-origin: var(--perspective_origin_x) var(--perspective_origin_y);
  -o-perspective-origin: var(--perspective_origin_x) var(--perspective_origin_y);
  perspective-origin: var(--perspective_origin_x) var(--perspective_origin_y);

  /*-webkit-box-reflect: below 100px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(0%, transparent), to(rgba(250, 250, 250, 0.1)));*/ }

.cube {
  position: relative;
  width: inherit;
  height: inherit;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateX(var(--rot_x)) rotateY(var(--rot_y));
  -moz-transform: rotateX(var(--rot_x)) rotateY(var(--rot_y));
  -ms-transform: rotateX(var(--rot_x)) rotateY(var(--rot_y));
  -o-transform: rotateX(var(--rot_x)) rotateY(var(--rot_y));
  transform: rotateX(var(--rot_x)) rotateY(var(--rot_y)); }

.cube > div {
  overflow: hidden;
  width: inherit;
  height: inherit;
  position: absolute;  
  opacity: 0.95;
  background:#051215;
  -webkit-touch-callout: none;
  -moz-touch-callout: none;
  -ms-touch-callout: none;
  -o-touch-callout: none;
  touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none; }

  .cube .cube-face-big {
  overflow: hidden;
  width: inherit;
  height: var(--sizecube);
  position: absolute;  
  opacity: 0.95;
}

.cube > div > div.cube-image {
  }

  .cube-image::before {
    content:'';
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background: black;
    opacity: 0.2;
  }
  .cube-image:hover::before {

      display: block;
      
  }

.cube > div:hover {
  cursor: auto; }

.cube > div:active {
  cursor: pointer; }

 .side .side_text { font-size: 16px; color: white; position: absolute; bottom: 0; right: 0; background-color: rgba(0, 0, 0, 0.4); max-width: 80%; display: inline-block; margin-bottom: 1%; margin-right: 1%; }

 .side .side_text .side_title_text {font-size: 80%; color: #ec8601;}

 .side .side_text .side_by_text {text-align: right; font-size: 70%; font-style: italic;}

 .side_quarter {float: left; min-width: 48%; width: 48%;  margin-left: 1%; margin-right: 1%; height: 32%; min-height: 32%; margin-top:1%; margin-bottom:1%;}
 .bottom_side_icon {color:#ec8601;  position:relative; left:33%; top:33%; margin:auto; font-size: 1.2em;}





.cube > div:first-child {
  -webkit-transform: rotateX(90deg) translateZ(var(--sizecube_2));
  -moz-transform: rotateX(90deg) translateZ(var(--sizecube_2));
  -ms-transform: rotateX(90deg) translateZ(var(--sizecube_2));
  -o-transform: rotateX(90deg) translateZ(var(--sizecube_2));
  transform: rotateX(90deg) translateZ(var(--sizecube_2));
  outline: 1px solid transparent; }

.cube > div:nth-child(2) {
  -webkit-transform: rotate(180deg) translateZ(var(--sizecube_2));
  -moz-transform: rotate(180deg) translateZ(var(--sizecube_2));
  -ms-transform: rotate(180deg) translateZ(var(--sizecube_2));
  -o-transform: rotate(180deg) translateZ(var(--sizecube_2));
  transform: rotate(180deg) translateZ(var(--sizecube_2));
  outline: 1px solid transparent; }

.cube > div:nth-child(3) {
  -webkit-transform: rotateY(90deg) rotate(180deg) translateZ(var(--sizecube_2));
  -moz-transform: rotateY(90deg) rotate(180deg) translateZ(var(--sizecube_2));
  -ms-transform: rotateY(90deg) rotate(180deg) translateZ(var(--sizecube_2));
  -o-transform: rotateY(90deg) rotate(180deg) translateZ(var(--sizecube_2));
  transform: rotateY(90deg) rotate(180deg) translateZ(var(--sizecube_2));
  outline: 1px solid transparent; }

.cube > div:nth-child(4) {
  -webkit-transform: rotateY(180deg) rotate(180deg) translateZ(var(--sizecube_2));
  -moz-transform: rotateY(180deg) rotate(180deg) translateZ(var(--sizecube_2));
  -ms-transform: rotateY(180deg) rotate(180deg) translateZ(var(--sizecube_2));
  -o-transform: rotateY(180deg) rotate(180deg) translateZ(var(--sizecube_2));
  transform: rotateY(180deg) rotate(180deg) translateZ(var(--sizecube_2));
  outline: 1px solid transparent; }

.cube > div:nth-child(5) {
  -webkit-transform: rotateY(-90deg) rotate(180deg) translateZ(var(--sizecube_2));
  -moz-transform: rotateY(-90deg) rotate(180deg) translateZ(var(--sizecube_2));
  -ms-transform: rotateY(-90deg) rotate(180deg) translateZ(var(--sizecube_2));
  -o-transform: rotateY(-90deg) rotate(180deg) translateZ(var(--sizecube_2));
  transform: rotateY(-90deg) rotate(180deg) translateZ(var(--sizecube_2));
  outline: 1px solid transparent; }

.cube > div:nth-child(6) {
  -webkit-transform: rotateX(-90deg) rotate(0deg) translateZ(var(--sizecube_4));
  -moz-transform: rotateX(-90deg) rotate(0deg) translateZ(var(--sizecube_4));
  -ms-transform: rotateX(-90deg) rotate(0deg) translateZ(var(--sizecube_4));
  -o-transform: rotateX(-90deg) rotate(0deg) translateZ(var(--sizecube_4));
  transform: rotateX(-90deg) rotate(0deg) translateZ(var(--sizecube_4));
  outline: 1px solid transparent; }

object {
  opacity: 0.5; }

object:hover {
  opacity: 1; }

@media screen and (max-width: 1051px) {
  .scroll_down_portada {
    font-size: 14px !important;
  }
}
@media screen and (min-width: 1050px) {
  .scroll_down_portada {
    font-size: 18px !important;
  }
}